<script setup>
import { ref, getCurrentInstance, computed } from 'vue'

import BaseButton from '@components/BaseComponents/BaseButton'

const step = ref(1)

const { $store } = getCurrentInstance().proxy

const backStep = () => step.value = step.value - 1
const nextStep = () => step.value = step.value + 1
const confirmIntegration = () => $store.dispatch('profile/updateAdvInfoStatus')

const advAgreementNotAccepted = computed(() => !$store.state.profile.advAgreementAccepted)
</script>

<template>
  <modal :value="advAgreementNotAccepted" forced class="ord-modal">
    <template slot="title">
      {{ $t('offers.adv.integration') }}
    </template>
    <template slot="body-content">
      <div>
        <div v-if="step === 1">
          <p class="ord-text">
            <tag-replacer :text="$t('offers.adv.ordWarn')">
              <template #link="{ content }">
                <a
                  href="https://www.consultant.ru/document/cons_doc_LAW_58968/092e570d75c4cbeaba90b9e7274c408f331aaeeb/"
                  target="_blank"
                >
                  {{ content }}
                </a>
              </template>
            </tag-replacer>
          </p>

          <p class="ord-text">{{ $t('offers.adv.automaticReporting') }}</p>

          <p class="ord-text">{{ $t('offers.adv.whatIsOrd') }}</p>

          <p class="ord-text">{{ $t('offers.adv.ordMeaning') }}</p>
        </div>

        <div v-if="step === 2">
          <p class="ord-text">
            {{ $t('offers.adv.forUser') }}
          </p>

          <img class="ord-img" :src="$asAppAsset('/images/platforms.png')" />

          <p class="ord-text">
            {{ $t('offers.adv.forUserDesc') }}
          </p>
        </div>

        <div v-if="step === 3">
          <p class="ord-text">
            {{ $t('offers.adv.correctDataTransfer') }}
          </p>

          <p class="ord-text">
            <tag-replacer :text="$t('offers.adv.correctDataTransferDesc')" />
          </p>

          <img class="ord-img" :src="$asAppAsset('/images/platform-modal.png')" />
        </div>
      </div>
    </template>

    <template slot="controls">
      <base-button
        v-if="step > 1"
        variant="secondary-action"
        @click="backStep"
      >
        {{ $t('global.common.back') }}
      </base-button>

      <base-button
        v-if="step < 3"
        variant="primary-action"
        @click="nextStep"
      >
      {{ $t('global.common.next') }}
      </base-button>

      <base-button
        v-if="step === 3"
        variant="primary-action"
        @click="confirmIntegration"
      >
      {{ $t('global.common.accessibly') }}
      </base-button>
    </template>
  </modal>
</template>

<style lang="scss" scoped>
.ord-modal {
  max-width: 600px;
  margin: 0 auto;

  & .ord-text {
    font-size: 16px;
    margin-bottom: 20px;

    & .main-text {
      color: $color-adverta-blue;
    }
  }

  & .ord-img {
    margin-bottom: 20px;
  }
}
</style>
