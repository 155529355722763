<script setup>
import { getCurrentInstance, computed, onMounted, ref } from 'vue'

import BaseButton from '@components/BaseComponents/BaseButton'

const { $store } = getCurrentInstance().proxy

const showedWarn = ref(true)

const hasRussianLinks = computed(() => $store.getters['dashboard/dashboardState']?.hasRussianLinks)
const showAuthWarn = computed(() => !showedWarn.value && !hasRussianLinks.value)


const closeModal = () => {
  showedWarn.value = true
  const cookieExpiresAt = new Date() 
  cookieExpiresAt.setDate(cookieExpiresAt.getDate() + 1)
  cookies.setItem('show_auth_warn_modal', 1, cookieExpiresAt)
}

const goToSettings = () => {
  closeModal()
  window.location.href = 'https://www.donationalerts.com/dashboard/general-settings/links'
}

onMounted(() => {
  showedWarn.value = !!cookies.getItem('show_auth_warn_modal')
})
</script>

<template>
  <modal :value="showAuthWarn" forced class="auth-warn-modal">
    <template slot="title">
      {{ $t('misc.authMessage.title') }}
    </template>
    <template slot="body-content">
      <div>
        <p class="auth-warn-text">
          {{ $t('misc.authMessage.text') }}
        </p>
      </div>
    </template>

    <template slot="controls">
      <base-button
        variant="secondary-action"
        @click="closeModal"
      >
        {{ $t('misc.authMessage.laterBtn') }}
      </base-button>

      <base-button
        variant="primary-action"
        @click="goToSettings"
      >
      {{ $t('misc.authMessage.settingsBtn') }}
      </base-button>
    </template>
  </modal>
</template>

<style lang="scss" scoped>
.auth-warn-modal {
  max-width: 600px;
  margin: 0 auto;

  & .auth-warn-text {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
</style>
